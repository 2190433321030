.mobile-menu {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: inline-flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    background: #FCFCFC;
    box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);
}