.tag-page {
    border-radius: 8px;
    background-color: #FCFCFC;
}

.tag-page-value {
    white-space: nowrap;
    margin: 10px 2px 10px 16px;
    display: flex;
    gap: 10px;
    position: relative;
    flex-direction: row;

    color: #353A41;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.tag-page-default {
    border: 1px solid #FCFCFC;
}

.tag-page-private {
    border: 1px solid #F66262;
}

.tag-page-for-friends {
    border: 1px solid #946BFF;
}