.header {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 83px;
    background-color: #FCFCFC;
    /*border-bottom: 5px solid #F1F2F4;*/
    box-shadow: 0px 0px 5px 0px rgba(87, 87, 87, 0.15);
}

.header-container {
    margin: auto;
    max-width: 1440px;
}

.btn-header {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: unset;
    color:#A5A5A5;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    padding-bottom: 12px;
    padding-top: 12px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.btn-header:hover {
    color: #808080;
}

.btn-header:disabled {
    color: #C5C5C5;
    opacity: 50%;
    cursor: auto;
}

.menu-wrapper {
    display: inline-flex;
    background-color: #FCFCFC;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
}

.menu-wrapper-mobile {
    display: inline-flex;
    background-color: #FCFCFC;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.menu-main {
    /*flex-grow: 1;*/
    display: inline-flex;
    flex-wrap: wrap;
    gap: 40px;
}

.menu-profile {
    float: right;
    display: flex;
    gap: 24px;
    padding-right: 40px;
    align-items: center;
    position: relative;
}

.menu-profile-unathorized {
    float: right;
    display: flex;
    gap: 12px;
    padding-right: 40px;
    align-items: center;
    position: relative;
}

.menu-logo {
    float: left;
    padding-left: 40px;
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    gap: 20px;
}
.menu-logo-mobile {
    float: left;
    padding-left: 40px;
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    gap: 5px;
}

.active-btn {
    color: #353A41 !important;
    /*padding-bottom: 12px;*/
}

.active-btn-border {
    border-bottom: 2px solid #353A41 !important;
    color: #353A41 !important;
}

.margin-top-search-include {
    padding-top: 265px;
}

.margin-top {
    padding-top: 100px;
}

.add-meme-button {
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    background: #946BFF;
}

.notification-button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.notification-button-count {
    position: absolute;
    left: 216px;
    top: 0;
    background: #946BFF;
    color: #FCFCFC;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    border-radius: 29px;
    padding: 2px;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
}