.meme-comment-wrapper {
    max-height: 505px;
    overflow: auto;
    /*width: 580px;*/
}

.meme-comments {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}