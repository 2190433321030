.tags-plug {
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    background: #F0F2F5;
    border: 1px solid #F0F2F5;

    color: #A5A5A5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}