.avatar-plug {
    width: 32px;
    padding: 6px 4px;
    justify-content: center;
    align-items: center;

    border-radius: 33px;
    border: 1px solid #FCFCFC;
    background: #F0F2F5;

    color: #808080;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

}