.notification-meme {
    padding: 12px 20px;
    border-top: 1px solid #cecece;
    display: flex;
    gap: 12px;
    position: relative;
}

.notification-meme-object-meme {
    text-align: left;
}

.notification-meme-actor {

}

.notification-meme-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.notification-meme-message {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #808080;
    text-align: left;
}

.notification-meme-user-link {
    color: #353A41;
    text-decoration: none;
}

.notification-meme-object {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.notification-meme-object img{
    width: 48px;
    height: 48px;
    border-radius: 2px;
}

.notification-meme-date {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-align: right;

    color: #C5C5C5;
}

.notification-meme-link {
    text-decoration: none;
}

.notification-meme-img {
    background-color: #F0F2F5;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: auto;
}

.notification-meme-read-dot {
    background-color: #946BFF;
    position: absolute;
    right: 10px;
    top: 16px;
    width: 10px;
    height: 10px;
    border-radius: 100px;
}