.skeleton {
    /*width: 100px;*/
    /*height: 100px;*/
    /*background-color: #6c6f85;*/
    --color: #C5C5C5;
    animation: fade 0.6s linear infinite alternate;
}

.skeleton-user-plug {
    background-color: var(--color);
    width: 32px;
    height: 32px;
}

.skeleton-user-name-plug {
    background-color:  var(--color);
    display: flex;
    width: 50px;
    height: 15px;
    border-radius: 8px;
}

.tag-skeleton {
    background-color:  var(--color);
    width: 65px;
    height: 42px;
    border-radius: 8px;
    display:inline-block;
    border: 1px solid #F0F2F5;
}

.meme-name-skeleton {
    height: 20px;
    width: 135px;
    background-color:  var(--color);
    border-radius: 8px;
}

.card-body-skeleton {
    position: relative;
    text-decoration: none;
    width: 298px;
    height: 320px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  var(--color);
}

.btn-mark-skeleton {
    width: 60px;
    height: 40px;
    background:  var(--color);
    border-radius: 56px;
}


@keyframes fade {
    from {
        opacity: .3;
    }
    to {
        opacity: 1;
    }
}