.edit-meme-content-wrapper {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
}

.edit-meme-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media screen and (max-width: 900px){
    .edit-meme-content {
        width: 100%;
    }
}