/*.user-row-friend {*/
/*    margin: 5px 5px 5px 5px;*/
/*    padding: 10px 3px 10px 3px;*/
/*    border-radius: 10px;*/
/*    text-align: center;*/
/*    display: inline-flex;*/
/*    justify-content: space-between;*/
/*    background-color: white;*/
/*    border:1px solid #cecece;*/
/*}*/

.friend-card {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 437px;
}

.friend-card-user-row {
    display: flex;
    gap: 12px;

    color: #353A41;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.mt8px {
    margin-top: 8px;
}

.friend-card-user-description {
    margin-top: 4px;
    height: 17px;
    text-align: left;

    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.friend-card-action-button {
    margin-top: 12px;
}

.friend-card-action-button button{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 48px;
    border: 1px solid #353A41;
}

@media screen and (max-width: 600px){
    .friend-card {
        width: 95%;
    }
}

