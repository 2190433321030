.message {
    text-align: center;
    padding-top: 16px;
    color: black;
    font-weight: 400;
}

.buttons {
    padding-top: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
}