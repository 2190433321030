.meme-add-comment-wrapper {
    padding-top: 24px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.meme-add-comment-container {
    display: flex;
    gap: 12px;
}

.meme-add-comment {
    /*border-top: 5px solid #cecece;*/
    display: flex;
    border: 1px solid #ABABAB;
    border-radius: 8px;
    min-height: 48px;
    /*margin: 20px;*/
    align-items: flex-start;
    width: 100%;
}

.meme-add-comment textarea {
    border: none;
    resize: none;
    width: 95%;
    background-color: #FCFCFC;
    margin: 2px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 35px;

    max-height: 150px;
}

.meme-add-comment textarea::placeholder {
    color: #ABABAB;
}

.meme-add-comment textarea:focus {
    outline: none !important;
    border: none;
    /*border:1px solid blue;*/
    /*box-shadow: 0 0 10px #719ECE;*/
}

.add-comment-button {
    /*margin: auto;*/
    margin-top: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: unset;
    color: black;
    cursor: pointer;
    font-style: normal;
}