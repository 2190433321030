.search-input {
    border: none; /* Убираем рамку */
    outline: none; /* Убираем свечение в Chrome и Safari */
    -webkit-appearance: none; /* Убираем рамку в Chrome и Safari */
    min-width: calc(100% - 32px);
    vertical-align: middle;
    border-radius: 8px;
    padding: 14px 14px;
    height: 48px;
    background-color: #FCFCFC;

    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.search-input::placeholder {
    color: #A5A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.input-field {
    display: flex;
    min-width: 322px;
    background-color: #FCFCFC;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
}

.input-field:hover {
    border: 1px solid #808080;
}

.input-field:focus-within {
    border: 1px solid #808080;
}

.search-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: unset;
    color: black;
    cursor: pointer;
    font-style: normal;
}

.input-field-with-border {

    display: flex;
    min-width: 322px;
    background-color: #FCFCFC;

    border: 1px solid #353A41;
    border-radius: 8px;
    padding: 1px;
}