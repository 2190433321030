.default {
    padding: 14px 20px;
    cursor: pointer;
    color: #FCFCFC;
    background: #946BFF;
    border-radius: 32px;
    border: #946BFF;

    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.default:hover {
    background: #8A5EFF;
    border-radius: 32px;
    border: #8A5EFF;
}

.default-white {
    padding: 14px 20px;
    background: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 48px;
    border: 1px solid #353A41;
    white-space: nowrap;
    cursor: pointer;
}

@media all and (max-width: 376px) {
    .default-white {
        padding: 7px 10px;
    }
    .default {
        padding: 7px 10px;
    }
}

.default-white:hover {
    color: #8A5EFF;
    border: 1px solid #8A5EFF;
}

.default[disabled] {
    background: #946BFF80;
    cursor: auto;
}

.max-width {
    width: 100%;
}

.default.activeTab {
    background-color: coral;
    color: black;
}

.default.disabled {
    background-color: grey;
    cursor: default;
}

