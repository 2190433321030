.selected-tags {
    overflow-x: scroll;
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    cursor: grabbing;
}

.selected-tags::-webkit-scrollbar {
    display: none;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    border-radius: 8px;
}
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    background-color: rgba(0, 0, 0, 0.01);
    transform: scale(0.95);
}