.mode-filter-option-enable {
    border-radius: 8px;
    background: #FCFCFC;
    box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);
}

.mode-filter-option-disable {
    color: #A5A5A5;
}

.option-disable-fill * {
    fill: #A5A5A5;
}

.mode-filter-option {
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}