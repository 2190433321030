.burger-background {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position:fixed;
    left: 0;
    display: flex;
}

.burger-menu-wrapper {
    width: 1440px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
}

.burger-menu {
    background: #FCFCFC;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    /*border-top: 1px solid #cecece;*/

    box-shadow: 0px 0px 8px 0px rgba(87, 87, 87, 0.15);

    padding-right: 40px;
    padding-left: 40px;
    align-items: flex-start;
}

@media screen and (max-width: 700px) {
    .burger-menu {
        width: 100%;
    }
}