/*.user-block {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin: 0 auto;*/
/*    text-align: center;*/
/*    align-items: flex-start;*/
/*    border-bottom: 1px solid #cecece;*/
/*}*/
/*.block {*/
/*    display: inline-block;*/
/*    margin: 15px;*/
/*    padding: 10px;*/
/*    border: 0;*/
/*    !*vertical-align: center;*!*/
/*}*/

/*.user-avatar {*/
/*    border-radius: 100px; !* Радиус скругления *!*/
/*    box-shadow: 0 0 0 3px white, 0 0 13px #333; !* Параметры теней *!*/
/*    width: 200px;*/
/*    height: 200px;*/
/*}*/

/*.user-description-block {*/
/*    background-color: white;*/
/*    !*height: 120px;*!*/
/*    !*min-height: 20px;*!*/
/*    !*max-height: 100px;*!*/
/*    border-radius: 10px;*/
/*    border: 2px #cecece solid;*/
/*    text-align: left;*/
/*    padding: 7px 7px 7px 7px;*/
/*}*/

/*.user-description-content {*/
/*    text-align: left;*/
/*}*/

.user-block {
    background-color: #FCFCFC;
    width: 322px;
    /*margin-left: 40px;*/
    margin-top: 32px;
    box-shadow: 0 4px 16px rgba(180, 180, 180, 0.2);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.user-block-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin-top: 16px;
}

.user-block-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    margin-top: 8px;
    color: #808080;
}

.user-block-friend-button {
    margin-top: 16px;
}

.user-block-friend-button button {

    border-radius: 48px;
    border: 1px solid #353A41;
    color: #353A41;

    background-color: #FCFCFC;

    height: 40px;
    width: 100%;
    cursor: pointer;
}

.add-friend-button {
    background-color: #946BFF !important;
    border: 1px solid #946BFF !important;
    color: #FCFCFC !important;
}

.remove-friend-button {
    background-color: transparent !important;
    border: 1px solid #353A41 !important;
    color: #353A41 !important;
}

.user-block-edit-buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.user-block-edit-buttons button[disabled] {
    border-radius: 48px;
    background: rgba(148, 107, 255, 0.50) !important;
    border: none;
    cursor: auto;
}

.user-block-edit {
    border-radius: 48px;
    background-color: #946BFF;
    border: 1px solid #946BFF;
    color: #FCFCFC;

    height: 40px;
    width: 100%;
    cursor: pointer;
}

.user-block-exit {
    border-radius: 48px;
    border: 1px solid #353A41;
    color: #353A41;
    background-color: #FCFCFC;
    height: 40px;
    width: 60px;
    cursor: pointer;
}

.user-block-exit {
    border-radius: 48px;
    border: 1px solid #353A41;
    color: #353A41;
    background-color: #FCFCFC;
    height: 40px;
    width: 60px;
    cursor: pointer;
}

.user-block-statistics {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 8px;
}

.user-block-statistic-link {
    text-decoration: none;
    color: #353A41;

    /*font-style: normal;*/
    /*font-weight: 600;*/
    /*font-size: 14px;*/
    /*line-height: 140%;*/
}
.user-block-statistic-count {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
}

.main-user-block-wrapper {
    min-width: 322px;
}

.main-user-block {
    position: fixed;
}

@media screen and (max-width: 1330px) {
    .main-user-block {
        position: relative;
    }
}

.user-block-status {
    /*margin-top: 8px;*/
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.friends-with-me {
    color: #FF8753;
}

.friends {
    color: #F66262;
}

.friends-approve {
    color: #946BFF;
}

.not-friends {
    color: #A5A5A5;
}