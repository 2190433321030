.login-window {
    display: flex;
    width: 438px;
    margin-top: 65px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    gap: 24px;
    background-color: #FCFCFC;
    padding: 32px 24px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(180, 180, 180, 0.20);
}

@media all and (max-width: 450px) {
    .login-window {
        width: 95%;
        margin-top: 0;
    }
}

.signin-input-area {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.signin-header {
    color: #353A41;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
}

.underline-link-button {
    color: #353A41;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-decoration-line: underline;
    cursor: pointer;
}

.signup-link-container {
    display: flex;
    justify-content: center;
    gap: 16px;

    color: #353A41;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.modal-reset-password {
    width: 438px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.reset-password-modal-title {
    color: #353A41;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.reset-password-modal-text {
    color: #353A41;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.reset-password-modal-text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}