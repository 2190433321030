#upload-input {
    display: none;
}

.crop {
    margin: auto;
    /*text-align: center;*/
    width: 500px;
    height: 300px;
    /*overflow: hidden;*/
}

.crop img {
    object-fit: cover;
    width: 600px;
    height: 400px;
}

.form-create-meme {
    width: 390px;
    margin: 0 auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.selected-file {
    /*margin-left: 10px;*/
}

.file-selector {
    /*margin-top: 20px;*/
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chosen-file {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.create-meme-window-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 65px;
    align-items: flex-start;
}

@media screen and (max-width: 900px) {
    .create-meme-window-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 0;
    }
    .create-meme-window {
        width: 100%;
    }
    .form-create-meme {
        width: 95%;
    }
}

.create-meme-window {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #FCFCFC;
    padding: 32px 24px;

    border-radius: 8px;

    box-shadow: 0 4px 16px 0 rgba(180, 180, 180, 0.20);
}

.create-meme-title {
    color: #353A41;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
}

.upload-btn {
    padding: 14px 20px;
    width: 100%;
    border: 1px solid #C5C5C5;
    border-radius: 48px;
    color: #353A41;
    cursor: pointer;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.upload-btn:hover {
    border: 1px solid #946BFF;
    color: #946BFF;
}
