.context-menu-option-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: unset;
    color: black;
    cursor: pointer;
    font-style: normal;
    height: 16px;
}

.context-menu-option-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.context-menu-option-content:hover {
    background-color: rgba(243, 243, 243, 0.99);
}