.meme-comment {
    /*border-bottom: 1px solid #cecece;*/
    text-align: left;
    display: flex;
    flex-direction: row;
    word-break: break-all;
    gap: 12px;
    color: #808080;
    /*background-color: #FCFCFC;*/
    /*background-color: orange;*/
}

.comment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.comment-user-name {
    color: #353A41;
    display: flex;
    gap: 8px;
}

.comment-date {
    display: flex;
    gap: 12px;
    color: #C5C5C5;
}

.comment-user-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comment-delete-button-link {
    color: #353A41;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
}

.comment-delete-button-link:hover {
    color: #FF5959;
}