.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position:fixed;
    top:0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    /*transition: 0.5s;*/
}

.modal.active {
    opacity: 1;
    pointer-events: all;
    z-index: 10;
}

.modal-content {
    /*padding: 20px;*/
    border-radius: 12px;
    background-color: white;
    /*width: 50vw;*/
    max-width: 50vw;
    transform: scale(0.5);
    transition: 0.4s all;
    /*margin: 10px 10px 10px 10px;*/
    padding: 32px 24px;
    color: black !important;
    position: relative;
    /*backface-visibility: hidden;*/
}

.modal-exit {
    position: absolute;
    right: 16px;
    top: 16px;
}

.modal-content.active {
    transform: scale(1);
    backface-visibility: hidden;
}