.space-for-tags {
    width: 100%;
    margin-top: 55px;
    position: absolute;
    max-height: 180px;
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    z-index: 10;

    border-radius: 8px;
    background: #FCFCFC;

    box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);

    overflow:hidden;
    overflow-y:auto;
}

.margin-more {
    margin-top: 135px;
}

.small-height {
    height: 76px !important;
    width: 100%;
}

.tag-not-found {
    color: #A5A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.create-tag {
    color: #353A41;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-decoration-line: underline;
    cursor: pointer;
    word-break: break-all;
}

.common-input-selected-tags {
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 14px 14px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    background-color: #FCFCFC;
    position: relative;
}
.common-input-selected-tags:hover {
    border: 1px solid #808080;
}

.common-input-selected-tags input {
    border: none; /* Убираем рамку */
    outline: none; /* Убираем свечение в Chrome и Safari */
    -webkit-appearance: none; /* Убираем рамку в Chrome и Safari */
    vertical-align: middle;
    border-radius: 4px;
    background-color: inherit;
}

.common-input-selected-tags input::placeholder {
    color: #A5A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.selected-tags-input {
    display: flex;
    height: 20px;
    justify-content: space-between;
}

.remove-all-tags-button {
    display: flex;
    flex-direction: row;
    gap: 6px;
    height: 20px;
    color: #A5A5A5;
    align-items: flex-start;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.remove-all-tags-button:hover {
    color: #FF5959;
}

.remove-all-tags-button:hover *  {
    fill: #FF5959;
}

.trash-svg {
    width: 16px;
    height: 16px;
}

.trash-svg * {
    fill: #A5A5A5;
}
