.search-user-window-background {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position:fixed;
    left: 0;
    display: flex;
}

.search-user-header {
    color: #353A41;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.search-user-window-wrapper {
    /*position: relative;*/
    width: 1440px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.search-user-window {
    background: #FCFCFC;
    width: 593px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    /*border-top: 1px solid #cecece;*/

    box-shadow: 0px 0px 8px 0px rgba(87, 87, 87, 0.15);

    padding-right: 40px;
    padding-left: 40px;
}

@media screen and (max-width: 700px) {
    .search-user-window {
        width: 100%;
    }
}

.exit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.search-user-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 72px;
    text-align: left;
}

.search-user-results-str {
    color: #353A41;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.search-user-results-str-count {
    color: #808080;
}

.search-users-results-users {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    overflow: auto;
    height: 520px;

}