.main-block-content {
    display: flex;
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    gap: 24px;
}

@media screen and (max-width: 900px) {
    .main-block-content {
        flex-wrap: wrap;
    }
}

.main-block-memes {
    width: 100%;
}

.user-block-statistics {
    color: #808080
}


.user-block-statistic-option {
    display: flex;
    justify-content: space-between;
}