.options-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

.options-button:hover * {
    fill: #946BFF;
}

.icon-svg-fill * {
    fill: #946BFF !important;
}

.icon-svg-default {
    fill: #353A41 !important;
}