.block-edit-meme {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
    gap: 24px;
}

.edit-meme-window-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 65px;
    align-items: flex-start;
}

.edit-meme-window {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #FCFCFC;
    padding: 32px 24px;

    border-radius: 8px;

    box-shadow: 0 4px 16px 0 rgba(180, 180, 180, 0.20);
}

.edit-meme-title {
    color: #353A41;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
}

.form-edit-meme {
    width: 390px;
    margin: 0 auto;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

@media screen and (max-width: 800px) {
    .block-edit-meme {
        flex-direction: column-reverse;
        align-items: center;
    }
    .form-edit-meme {
        width: 95%;
    }
    .edit-meme-window {
        width: 100%;
    }
}