.meme-comment-block-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.comments-header {
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
}

.meme-comment-block {
    /*border: 5px solid #cecece;*/
    background-color: #FCFCFC;
    border-radius: 10px;
    width: 553px;
}

@media screen and (max-width: 600px) {
    .meme-comment-block {
        width: 100%;
    }
}
