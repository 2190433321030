.card-owners {
    height: 34px;
}

.card-owners-user-rows {
    position: relative;
    display: flex;
    flex-direction: row;
    /*gap: 20px;*/
    justify-content: space-between;
    padding-right: 12px;
}

.card-owners-other-owners {
    position: relative;
}

.zam {
    position: absolute;
}