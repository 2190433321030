.search {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 24px;
    max-width: 1440px;
    flex-wrap: wrap;
}

.search-by-tags {
    display: flex;
    flex-direction: column;
    min-width: 292px;
    text-align: left;
    gap: 12px;
}

.search-by-tags .title {
    font-style: normal;
    line-height: 140%;

    color: #353A41;
    font-size: 16px;
    font-weight: 500;
}

.search-by-tags .tags-selector {
    width: 100%;
}

.search-by-name {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 292px;
    width: 437px;
    gap: 12px;
}

.search-by-name .title {
    font-style: normal;
    line-height: 140%;

    color: #353A41;
    font-size: 16px;
    font-weight: 500;
}

.search-order-by {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;

    font-style: normal;
    line-height: 140%;

    color: #353A41;
    font-size: 16px;
    font-weight: 600;
}

