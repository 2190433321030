.tags {
    overflow-x: scroll;
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.tags::-webkit-scrollbar {
    display: none;
}