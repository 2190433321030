.card {
    page-break-inside: avoid;
    break-inside: avoid;

    width: 322px;

    background: #FCFCFC;
    border-radius: 8px;

    padding: 12px 0 12px 12px;

    display: flex;
    flex-direction: column;
    gap: 12px;
}

.card-img {
    border-radius: 8px;
}

.card-body {
    position: relative;
    text-decoration: none;
    font-size: 27px;
    font-weight: 500;
    color: black;
    width: 298px;
    height: 320px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F2F5;
}

@media all and (max-width: 376px) {
    .card {
        width: 292px;
    }
    .card-body {
        width: 270px;
    }
}

.card-body img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: auto;
}

.card-title {
    margin: 0 10px 10px 10px;
    font-size: 20pt;
}

.card-sub {
    font-size: 12px;
    font-weight: 300;
    margin-top: 20px;
}

.btn-keeper {
    padding-right: 12px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.comment-button {
    width: 60px;
    height: 40px;

    background: #FCFCFC;
    border: none;
    cursor: pointer;
}

/*.comment-button:hover * {*/
/*    fill: #946BFF !important;*/
/*}*/

.comment-svg:hover * {
    fill: #946BFF !important;
}

.meme-private-mode {
    position: absolute;
    top: 8px;
    left: 257px;
    right: 8px;
    bottom: 277px;
    background-color: #FCFCFC;
    /*opacity: 50%;*/
    border-radius: 8px;
}

.meme-private-mode:hover {
    z-index: -1;
}

.card-meme-name {
    min-height: 20px;
    color: #353A41;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.card-meme-name-plug {
    color: #A5A5A5 !important;
    font-weight: 500 !important;
}