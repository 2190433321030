.back-button {
    float: left;
    margin-top: 40px;
    /*margin-left: 40px;*/

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

.back-button-content {
    display: flex;
    align-items: center;
    gap: 8px;
}