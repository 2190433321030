.meme-card-detail {
    width: 783px;
    /*height: 800px;*/
    background-color: #FCFCFC;
    box-shadow: 0 4px 16px rgba(180, 180, 180, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin: auto;
    margin-bottom: 20px;
}

.meme-card-detail .content {
    text-decoration: none;
    font-size: 27px;
    font-weight: 500;
    color: black;
    width: 743px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-radius: 8px;
}

.meme-card-detail .content img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: auto;
}

.meme-card-name {
    text-align: left;
    color: #353A41;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
}

@media screen and (max-width: 900px) {
    .meme-card-detail {
        width: 100%;
    }
    .meme-card-detail .content {
        width: 100%;
    }
}


