.friends-list-wrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.empty-friend-category {
    text-align: left;
    margin-left: 20px;
}

.friends-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-bottom: 20px;
}

.friend-list button{
    margin-right: 7px;
}

.friends-search {
    width: 437px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;

    color: #353A41;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

@media screen and (max-width: 1150px){
    .friends-list {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
    }
    .mode-filter {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px){
    .friends-search {
        width: 95%;
    }
}
