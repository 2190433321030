.user-name {
    margin: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.user-link {
    color: black;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.user-wrapper {
    display: flex;
    gap: 12px;
}

.user-row-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0;
    background-color: unset;
    color: black;
    font-size: 12pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
}