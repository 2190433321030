.delete-tag {
    display: inline-flex;
}

.tag-editor-wrapper {
    width: 450px;
    margin: auto;
}

.tag-editor {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}

.tags-selector {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.tag-page-content {
    margin: 40px;
}

.tag-page-collection-wrapper {
    margin-top: 24px;
}

.tag-search-block {
    display: flex;
    gap: 24px;
}

.tag-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    min-width: 322px;
    width: 688px;
}

.tag-create {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    min-width: 322px;
    width: 688px;
}

.tag-create-header {
    display: flex;
    gap: 8px;
}

.tag-create-content {
    display: flex;
    gap: 16px;
    width: 100%;
}

.tag-create-content input {
    align-self: stretch;
}

.tag-create-button {
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 48px;
    border: 1px solid #353A41;
    white-space: nowrap;
    cursor: pointer;
}

.tag-create-button:hover {
    border: 1px solid #946BFF;
    color: #946BFF;
}

.private-mode-filter-block {
    margin-top: 40px;
}

@media screen and (max-width: 900px) {
    .tag-search-block {
        display: flex;
        flex-direction: column;
    }
    .tag-create {
        width: 95%;
    }
    .tag-search {
        width: 95%;
    }
    .tag-create-content {
        width: 95%;
        flex-direction: column;
    }
}