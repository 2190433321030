.options-wrapper {
    background: #FCFCFC;
    box-shadow: 0 0 16px rgba(105, 105, 105, 0.1);
    border-radius: 8px;
    border: 2px solid rgba(105, 105, 105, 0.1);
    position: absolute;
    margin-top: 40px;
    margin-right: 12px;
    padding: 16px;
    z-index: 9;
    right: 0;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 12px;
}