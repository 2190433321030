.mark-buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.mark-button .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.btn-like:hover * {
    fill: #946BFF;
}

.btn-dislike:hover * {
    fill: #FF5959;
}

.active-like {
    background-color: #946BFF !important;
    transition: background-color 1s ease;
    color: #FCFCFC;
}

.active-dislike {
    background-color: #FF5959 !important;
    transition: background-color 1s ease;
    color: #FCFCFC;
}

.fill-mark-svg * {
    fill: #FCFCFC !important;
}

.mark-button {
    width: 60px;
    height: 40px;
    background: #F0F2F5;
    border-radius: 56px;
    border: 0;
    cursor: pointer;
}