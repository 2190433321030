.common-input {
    border-radius: 8px;
    display: flex;
    gap: 10px;
    height: 48px;
    font-size: 16px;
    line-height: 125%;
    padding: 14px 16px;
    background: #FCFCFC;
    border: 1px solid #E7E7E7;
}
.common-input:hover {
    border: 1px solid #808080;
}

.common-input input {
    border: none; /* Убираем рамку */
    outline: none; /* Убираем свечение в Chrome и Safari */
    -webkit-appearance: none; /* Убираем рамку в Chrome и Safari */
    vertical-align: middle;
    border-radius: 4px;
    background-color: inherit;
}

.common-input input::placeholder {
    color: #A5A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}

.common-input:focus-within {
    border: 1px solid #808080;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.input-error-message {
    color: #F66262;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: left;
}

.error-input {
    border: 1px solid #F66262;
    background: #FFEFEF;
}

.common-input button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext-container {
    visibility: hidden;
    /*width: 280px;*/
    /*height: 104px;*/
    text-align: left;

    background: #FCFCFC;
    box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);
    border-radius: 4px;

    position: absolute;
    z-index: 1;
    bottom: 22px;
    left: -21px;
    display: flex;
}

.tooltiptext {
    overflow: hidden;
    color: #808080;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 8px;
}



/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext-container {
    visibility: visible;
}