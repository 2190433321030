.notification-wrapper {
    background-color: white;
    height: 435px;
    width: 360px;
    margin-right: 99px;
    box-shadow: 0 0 16px rgba(105, 105, 105, 0.1);
    border-radius: 8px;
    display: flex;
    right: 0;
    z-index: 10;
    flex-direction: column;
    float: right;
}

@media screen and (max-width: 700px) {
    .notification-wrapper {
        width: 100%;
        float: none;
    }
}

.notifications {
    overflow: auto;
}

.notification-header {

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;

    text-align: center;
    color: #353A41;

    padding: 16px 20px;

    border-bottom: 1px solid #cecece;
}