.create-tag-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    word-break: break-all;
}

.create-tag-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
}