.tag-page-collection {
    /*display: grid;*/
    /*gap: 24px;*/
    /*grid-template-columns: 2;*/
    /*position: relative;*/

    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: row;
}