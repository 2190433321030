.user-menu-container-wrapper {
    width: 322px;
    border-radius: 8px;
    background: #FCFCFC;
    /*position: absolute;*/
    position: relative;
    z-index: 10;
    right: 40px;
    margin-left: auto;
    /*margin-right: auto;*/

    box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);
}

@media screen and (max-width: 900px){
    .user-menu-container-wrapper {
        width: 322px;
        border-radius: 8px;
        background: red;
        position: absolute;
        z-index: 10;
        right: 40px;

        box-shadow: 0 0 8px 0 rgba(87, 87, 87, 0.15);
    }
}

.user-menu-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.user-menu-avatar {
    color:#353A41;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.user-menu-empty-space {
    height: 16px;
}

.user-menu-actions {
    color: #353A41;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
    padding-left: 20px;
    padding-top: 16px;
    border-top: 1px solid #E7E7E7;
}

.user-menu-button {
    cursor: pointer;
}