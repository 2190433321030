.not-found-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 668px;
    margin: 80px auto auto;
    align-items: center;
}

@media screen and (max-width: 900px){
    .not-found-content {
        width: 95%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

.not-found-img {
    width: 300px;
    height: 350px;
    margin: auto;

    background: url(../../assets/images/dog-logo-large.svg), lightgray 50% / cover no-repeat;
    mix-blend-mode: multiply;
}

/*.not-found-img img{*/
/*    background: none;*/
/*}*/

.not-found-title {
    color: #353A41;
    text-align: center;

    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    /*margin-top: 48px;*/
}

.not-found-text {
    color: #353A41;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    margin-top: 16px;
    margin-bottom: 24px;
}