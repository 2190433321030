.scroll-to-top-button {
    position: fixed;
    /*top: 80%;*/
    bottom: 50px;
    right: 10%;
    background-color: #F0F2F5;
    border: 2px solid #A5A5A5;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-to-top-button:hover {
    border: 2px solid #353A41;
}