.window-choose-one-of-similar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.window-choose-one-of-similar-memes {
    display: flex;
    flex-direction: row;
    gap: 8px;
    /*padding: 32px 24px;*/
}

.variant-wrapper {
    display: flex;
    flex-direction: column;
}

.variant {
    page-break-inside: avoid;
    break-inside: avoid;

    /*background: #F0F2F5;*/
    border-radius: 8px;

    display: flex;
    justify-content: center;
    width: 191px;
    height: 200px;
}

.variant-name {
    background-color: #FCFCFC;
    padding-top: 8px;
}

.variant img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin: auto;
}

.window-choose-one-of-similar-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.window-choose-one-of-similar-title {
    color: #353A41;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
}

.window-choose-one-of-similar-text {
    color: #353A41;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
}

.active-choice {
    border: 4px solid #946BFF;
}