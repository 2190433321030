.edit-user-page-inputs {
    margin-top: 32px;
    display: flex;
    gap: 24px;
    width: 437px;
}

.edit-user-page-main-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.edit-user-page-input {
    width: 437px;
}

.edit-page-avatar-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.edit-user-page-avatar-container {
    display: flex;
    gap: 12px;
}

.upload-avatar-button {
    color: #353A41;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    cursor: pointer;
}

.edit-profile-save-changes-button {
    width: 207px;
}

@media screen and (max-width: 1100px) {
    .edit-user-page-inputs {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .edit-user-page-input {
        width: 95%;
    }
}