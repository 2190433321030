.private-mode-selector {
    text-align: center;
    color: #A5A5A5;
}

.private-mode-options {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}