* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /*background-color: rgba(238, 238, 238);*/
  background-color: #F1F2F4;
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  background-color: #F1F2F4;
  /*max-width: 1440px;*/
  margin: 0 auto;
}

button {
  font-family: 'Inter', sans-serif;
}

.no-border-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}

.header-main {
  color: #353A41;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.pointer {
  cursor: pointer;
}

.app-global-container {
  max-width: 1440px;
  margin: auto;
}
