.tag {
    display:inline-block;
    background: #F0F2F5;
    border-radius: 8px;
    max-height: 40px;
}

.tag-value {
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 16px;
    display: flex;
    gap: 10px;
}

.for-creatable {
    cursor: pointer;
}

.for-creatable:hover {
    color: #8A5EFF;
}

.tag-default {
    border: 1px solid #F0F2F5;
}

.tag-private {
    border: 1px solid #F66262;
}

.tag-for-friends {
    border: 1px solid #946BFF;
}

.tag-pointer {
    cursor: pointer;
}

.tag-delete:hover * {
    /*fill: #F66262 !important;*/
    background-color: #717173;
    border-radius: 100px;
}