.private-selector-meme-option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0;
    background-color: unset;
    cursor: pointer;
    font-style: normal;
    color: #A5A5A5;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
}

.active-option-private {
    color: #F66262;
}

.active-option-friends {
    color: #946BFF;
}

.active-option-public {
    color: #353A41;
}