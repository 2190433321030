.meme-detail-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /*flex-wrap: wrap;*/
    gap: 20px;
}

@media screen and (max-width: 900px) {
    .meme-detail-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.meme-detail-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}

.back-button-content span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}
